import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric-with-erasing';

const WallTextureCanvas = ({ imageUrl, detections, imageWidth, imageHeight }) => {
    const mainCanvasRef = useRef(null);

    // Function to process DirectAI response and filter for walls and textures
    const processDirectAIResponse = (data) => {
        const detectionResults = data[0];
        if (detectionResults && Array.isArray(detectionResults)) {
            return detectionResults
                .filter(detection => detection.class === 'bricks' || detection.class === 'texture')
                .map(detection => {
                    const [y_min, x_min, y_max, x_max] = detection.tlbr;
                    return {
                        label: detection.class,
                        bounding_box: {
                            x_min,
                            y_min,
                            x_max,
                            y_max
                        }
                    };
                });
        }
        return [];
    };

    // Convert bounding boxes into polygons with control points
    const createEditablePolygon = (canvas, boundingBox, scaleFactorX, scaleFactorY) => {
        const { x_min, y_min, x_max, y_max } = boundingBox;

        // Define the four corners as points for the polygon
        const points = [
            { x: x_min * scaleFactorX, y: y_min * scaleFactorY },
            { x: x_max * scaleFactorX, y: y_min * scaleFactorY },
            { x: x_max * scaleFactorX, y: y_max * scaleFactorY },
            { x: x_min * scaleFactorX, y: y_max * scaleFactorY }
        ];

        const polygon = new fabric.Polygon(points, {
            fill: 'rgba(0, 0, 255, 0.3)',
            stroke: 'blue',
            strokeWidth: 2,
            selectable: true,
            objectCaching: false,
            hasBorders: true,
            hasControls: true,
            lockScalingFlip: true,
            perPixelTargetFind: true,  // Improves click detection on points
            cornerStyle: 'circle',  // Set style for better visual
            cornerColor: 'red',  // Change color for visibility
        });

        // Add interactive controls to each point on the polygon
        polygon.controls = polygon.points.reduce((acc, point, index) => {
            acc[`p${index}`] = new fabric.Control({
                positionHandler: (dim, finalMatrix, fabricObject) => {
                    return fabric.util.transformPoint(
                        {
                            x: fabricObject.points[index].x - fabricObject.pathOffset.x,
                            y: fabricObject.points[index].y - fabricObject.pathOffset.y,
                        },
                        fabricObject.calcTransformMatrix()
                    );
                },
                actionHandler: (eventData, transform, x, y) => {
                    const polygon = transform.target;
                    const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
                    polygon.points[index] = mouseLocalPosition;
                    return true;
                },
                render: fabric.controlsUtils.renderCircleControl,
                cornerSize: 8,
                pointIndex: index
            });
            return acc;
        }, {});

        canvas.add(polygon);
        return polygon;
    };

    // Initialize the canvas and draw detections
    useEffect(() => {
        const canvasElement = mainCanvasRef.current;
        const canvas = new fabric.Canvas(canvasElement);

        const container = canvasElement.parentNode;
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;

        canvas.setWidth(containerWidth);
        canvas.setHeight(containerHeight);

        const scaleFactorX = containerWidth / imageWidth;
        const scaleFactorY = containerHeight / imageHeight;

        // Load the background image and scale it to fit the canvas
        fabric.Image.fromURL(imageUrl, (img) => {
            const scaleFactor = Math.min(scaleFactorX, scaleFactorY);
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                scaleX: scaleFactor,
                scaleY: scaleFactor,
                originX: 'left',
                originY: 'top'
            });

            if (detections) {
                const filteredDetections = processDirectAIResponse(detections);
                filteredDetections.forEach(detection => {
                    createEditablePolygon(canvas, detection.bounding_box, scaleFactorX, scaleFactorY);
                });
            }
        });

        return () => {
            canvas.dispose();
        };
    }, [imageUrl, detections, imageWidth, imageHeight]);

    return (
        <div className="canvasContainer" style={{ width: '100%', height: '100%' }}>
            <canvas ref={mainCanvasRef} id="canv" />
        </div>
    );
};

export default WallTextureCanvas;
