// colorUtils.js

// Import your colorList here
import { colorList } from './colorHelper'; // Make sure to use the correct path to the color data

// Normalize item keys for consistent matching
export const normalizeItemKey = (key) => {
  return key
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove accents
    .toLowerCase()
    .replace(/\s+/g, '') // Remove all spaces
    .trim();
};

// Normalize proposed colors based on language
export const normalizeProposedColors = (colors, lang, itemMapping) => {
  const normalizedColors = {};
  Object.keys(colors).forEach(key => {
    const normalizedKey = normalizeItemKey(key); // Normalize keys
    const translatedKey = itemMapping[lang]?.[normalizedKey] || normalizedKey; // Translate based on language
    normalizedColors[translatedKey] = colors[key];
  });
  return normalizedColors;
};

// Function to normalize text to camelCase format
export const normalizeToCamelCase = (text) => {
  return text
    .normalize("NFD")                  // Separate accents from characters
    .replace(/[\u0300-\u036f]/g, "")    // Remove accents
    .toLowerCase()                     // Convert to lowercase
    .replace(/\s+/g, ' ')              // Replace multiple spaces with a single space
    .trim()                            // Remove leading/trailing spaces
    .split(' ')                        // Split by space to handle multi-word colors
    .map((word, index) => {
      return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('');                         // Join into a single camelCase string
};

// Consolidated getColorByName function
export const getColorByName = (name) => {
  if (!name) return '#ffffff'; // Default to white if name is missing

  // Normalize the color name for consistent comparison
  const formattedName = normalizeToCamelCase(name);
  console.log(`Matching color for: ${formattedName}`);
  console.log(`Searching for color: ${formattedName}`);

  // Search for the color in your colorList
  const colorObj = colorList.find(color =>
    normalizeToCamelCase(color.name) === formattedName ||
    normalizeToCamelCase(color.fr) === formattedName
  );

  // console.log(`Matched color: ${colorObj ? colorObj.color : 'No Match Found'}`);
  return colorObj ? colorObj.color : null; // Return null if no match is found
};


