import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { getAuth } from 'firebase/auth';

const SecondaryNav = () => {
  const { t, i18n } = useTranslation(["translation"]);
  const auth = getAuth(); // Get Firebase Auth instance

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'fr' : 'en';
  
    if (window.confirm(t("changeLanguageMessage"))) {
      i18n.changeLanguage(newLanguage, (err) => {
        if (!err) {
          auth.languageCode = newLanguage;
          window.location.reload();
        } else {
          console.error("Error changing language in i18next:", err);
        }
      });
    }
  };
  
  

  const languageLabel = () => {
    return i18n.language === 'en' ? t("translation:fr") : t("translation:en");
  };

  return (
    <div className="secondNavContainer">
      <Navbar bg="light" >
        <Navbar.Toggle aria-controls="secondary-navbar-nav" />
        <Navbar.Collapse id="secondary-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#contact">Contact</Nav.Link>
            <Nav.Link href="#about">{t("about")}</Nav.Link>
            <Nav.Link href="#howitworks">{t("homepage.how_it_works.title")}</Nav.Link>
            {/* Additional navigation links can be uncommented and used here */}
          </Nav>
          <Nav.Item>
            <div className="nav-link" onClick={toggleLanguage} style={{ cursor: 'pointer', paddingRight: "15px" }}>
              {languageLabel()}
            </div>
          </Nav.Item>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default SecondaryNav;
