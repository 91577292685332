import React, { useState, useMemo, useEffect } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";
import { useAuth, db } from "../base"; // Firebase configuration
import { doc, updateDoc, getDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import EyeDropperComponent from "./eyeDropper/EyeDropperComponent";
import presetColors from "../components/colors/presetColors"; // Import the external preset colors

function ItemColors(props) {
  const { t, i18n } = useTranslation(["translation"]);
  const [selectedColor, setSelectedColor] = useState("#ffffff");
  const [customColors, setCustomColors] = useState([]);
  const [colorName, setColorName] = useState("");
  const [isCustomColorsVisible, setIsCustomColorsVisible] = useState(false);
  const [showNamePrompt, setShowNamePrompt] = useState(false);
  const [pickedColor, setPickedColor] = useState("");
  const [selectedMode, setSelectedMode] = useState("Selected Item");
  const [isPickerVisible, setIsPickerVisible] = useState(false);

  const currentUser = useAuth(); // Get the current logged-in user

  const togglePickerVisibility = () => {
    setIsPickerVisible(!isPickerVisible);
  };

  const closePicker = () => {
    setIsPickerVisible(false);
  };

  // Use the translated presetColors array
  const translatedPresetColors = useMemo(() => {
    return presetColors.map((colorObj) => ({
      ...colorObj,
      title: t(colorObj.titleKey),
    }));
  }, [i18n.language, t]);

  // Fetch custom colors from Firebase for the logged-in user
  useEffect(() => {
    const fetchCustomColors = async () => {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          setCustomColors(userDocSnap.data().customColors || []);
        }
      }
    };

    fetchCustomColors();
  }, [currentUser]);

  // Utility function to convert Hex to RGBA
  const hexToRgba = (hex, alpha = 1) => {
    if (hex.includes("rgba") || hex.includes("rgb")) {
      return hex; // Return the same rgba value
    }

    if (!hex || typeof hex !== 'string' || hex.length !== 7 || hex[0] !== '#') {
      console.error("Invalid hex format:", hex);
      return `rgba(0, 0, 0, ${alpha})`;
    }

    let r = parseInt(hex.substring(1, 3), 16);
    let g = parseInt(hex.substring(3, 5), 16);
    let b = parseInt(hex.substring(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  // Save custom color to Firestore
  const saveColorToFirebase = async (pickedColor) => {
    if (currentUser) {
      const userDocRef = doc(db, "users", currentUser.uid);
      try {
        await updateDoc(userDocRef, {
          customColors: arrayUnion(pickedColor),
        });
        setCustomColors([...customColors, pickedColor]);
      } catch (error) {
        console.error("Error saving color to Firestore: ", error);
      }
    }
  };

  // Delete custom color from Firestore
  const deleteColorFromFirebase = async (colorObject) => {
    if (currentUser) {
      const userDocRef = doc(db, "users", currentUser.uid);
      try {
        await updateDoc(userDocRef, {
          customColors: arrayRemove(colorObject),
        });
        setCustomColors(customColors.filter((item) => item.color !== colorObject.color));
      } catch (error) {
        console.error("Error removing color from Firestore: ", error);
      }
    }
  };

  // Handle color change and apply the color to the items
  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    const rgbaColor = hexToRgba(color.hex);

    let itemType = "";
    if (selectedMode === "All Windows") {
        itemType = "window";
    } else if (selectedMode === "All Doors") {
        itemType = "doors";
    }

    if (selectedMode === "Selected Item") {
        // Apply color only to the selected item
        if (props.updateColor) {
            props.updateColor(color.hex);
        }
        if (props.handleColor) {
            props.handleColor(color);
        }
    } else if (itemType && props.applyColorToSpecificItems) {
        // Apply color to all items of the specified type
        console.log(`Applying color to all ${itemType}`);
        props.applyColorToSpecificItems(itemType, rgbaColor);
    } else {
        console.error("No valid itemType or applyColorToSpecificItems function");
    }

    // Close the picker
    closePicker();
};



  // Handle custom color selection and use the same system as default colors
  const handleCustomColorSelect = (colorObject) => {
    handleColorChange({ hex: colorObject.color });
  };

  const toggleCustomColorsVisibility = () => {
    setIsCustomColorsVisible(!isCustomColorsVisible);
  };

  // Show custom color name prompt
  const handleShowNamePrompt = (color) => {
    setPickedColor(color.hex);
    setShowNamePrompt(true); // Show the name input dialog
  };

  // Handle saving the custom color with a name
  const handleColorNameSubmit = (e) => {
    e.preventDefault();
    const colorObject = {
      color: pickedColor,
      title: `Custom - ${colorName}`,
    };
    saveColorToFirebase(colorObject);
    setSelectedColor(colorObject.color);
    setShowNamePrompt(false);
    setColorName("");
  };

  // Custom swatch component
  const CustomSwatch = ({ color, title, onClick }) => (
    <div
      className="color-swatch"
      style={{
        backgroundColor: color,
        height: "30px",
        width: "30px",
        margin: "3px",
        cursor: "pointer",
        border: "2px solid #ffffff",
        borderRadius: "4px",
      }}
      onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.6)")}
      onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      onClick={onClick}
      title={title}
    />
  );

  return (
    <div>
      <Tippy
       interactive={true}
       placement={"left"}
       visible={isPickerVisible} // Control visibility with state
       onClickOutside={closePicker} // Close when clicking outside
        content={
          <div
            className="sketch-picker-container"
            style={{
              width: "300px",
              height: "480px",
              overflowY: "auto",
              borderRadius: "8px",
              border: "1px solid #ddd",
              backgroundColor: "#333",
              color: "#fff",
            }}
          >
            {/* <div style={{ padding: "10px" }}>
              <label>
                <input
                  type="checkbox"
                  checked={isPickerVisible}
                  onChange={(e) => setIsPickerVisible(e.target.checked)}
                />
                {t("Keep Picker Open")}
              </label>
            </div> */}
            <div style={{ marginBottom: "15px", padding: "10px" }}>
              <label style={{ fontSize: "16px", fontWeight: "bold", color: "#fff" }}>{t("itemColor-applyMode")}</label>
              <select
                style={{ marginLeft: "10px", backgroundColor: "#555", color: "#fff" }}
                value={selectedMode}
                onChange={(e) => setSelectedMode(e.target.value)}
              >
                <option value="Selected Item">{t("itemColor-selectedItem")}</option>
                <option value="All Windows">{t("itemColor-allWindows")}</option>
                <option value="All Doors">{t("itemColor-allDoors")}</option>
              </select>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "15px" }}>
              {translatedPresetColors.map((colorObj, index) => (
                <CustomSwatch key={index} color={colorObj.color} title={colorObj.title} onClick={() => handleColorChange({ hex: colorObj.color })} />
              ))}
            </div>
            {showNamePrompt && (
              <div className="customColorName-prompt">
                <form onSubmit={handleColorNameSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <input
                    type="text"
                    value={colorName}
                    onChange={(e) => setColorName(e.target.value)}
                    placeholder={t("itemColor-entercolorname")}
                    required
                    style={{ marginBottom: "10px" }}
                  />
                  <button type="submit">{t("itemColor-save")}</button>
                </form>
              </div>
            )}

            <h4
              onClick={toggleCustomColorsVisibility}
              style={{ cursor: 'pointer', fontSize: 'medium', color: "#4CAF50", marginBottom: "10px" }}
            >
              {t("itemColor-customcolors")}
            </h4>
            {isCustomColorsVisible && (
              <div style={{ display: "flex", flexWrap: "wrap", padding: "10px" }}>
                {customColors.map((colorObject, index) => (
                  <div key={index} className="custom-color-entry" style={{ marginRight: "5px", marginBottom: "5px" }}>
                    <CustomSwatch color={colorObject.color} title={colorObject.title} onClick={() => handleCustomColorSelect(colorObject)} />
                    <span className="color-title">{colorObject.title.replace("Custom - ", "")}</span>
                    <span
                      className="delete-color-x"
                      onClick={() => deleteColorFromFirebase(colorObject)}
                      style={{ cursor: 'pointer', color: "white", padding: '0 2px', fontSize: 'larger' }}
                    >
                      X
                    </span>
                  </div>
                ))}
              </div>
            )}

            <EyeDropperComponent
              onColorSelected={(color) => {
                handleShowNamePrompt({ hex: color });
              }}
            />
          </div>
        }
      >
        <DropdownButton
          title={
            <img
              className={"colorButton"}
              src="./icons/colorBucketButton.png"
              height="40px"
              alt="Color"
              title={t("itemColor-color")}
            />
          }
          onClick={togglePickerVisibility} // Toggle visibility  

          className="btn mt-1 btn-block pb-0 m-0"
          id="dropdown-button-dark"
          variant="secondary"
          size="sm"
          drop="end"


        />

      </Tippy>

    </div>
  );
}

export default ItemColors;
