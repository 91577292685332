import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fabric } from "fabric-with-erasing";

export default function ItemDetailsModal({ selectedObject, isOpen, details, isDoor, imageUrl, itemColor, onClose }) {
  const [processedImageUrl, setProcessedImageUrl] = useState(null);
  const { t } = useTranslation(["translation"]);

  useEffect(() => {
    if (!isOpen || !selectedObject) {
      console.warn("Modal not ready: No object selected on the canvas.");
      return;
    }

    // Generate the image with grids, decorative glass, and applied color
    generateImageFromCanvasObject(selectedObject, itemColor, (result) => {
      setProcessedImageUrl(result || null); // Use result or fallback
    });
  }, [isOpen, selectedObject, itemColor]);

  if (!isOpen || !details || Object.keys(details).length === 0) {
    console.warn("Modal not open or missing details.");
    return null;
  }

  return (
    <div className="detailsModal-backdrop">
      <div className="detailsModal">
        <button className="detailsModal-closeButton" onClick={onClose}>
          &times;
        </button>
        <div className="detailsModal-content">
          {processedImageUrl ? (
            <div className="detailsModal-imageContainer">
              <div className="detailsModal-imageOverlay">
                <img
                  className="detailsModal-image"
                  src={processedImageUrl}
                  alt="Item Preview"
                />
              </div>
            </div>
          ) : (
            <p>{t("Image not available")}</p>
          )}
          <div className="detailsModal-details">
            <h2 className="detailsModal-title">{t("details.itemDetails")}</h2>
            <ul className="detailsModal-list">
              <li className="detailsModal-item">
                <strong>{t("details.itemType")}:</strong> {t(preprocessWindowType(details.windowType, imageUrl))}
              </li>
              {/* <li className="detailsModal-item">
                  <strong>{t("details.optionType")}:</strong> {details.optionType}
                </li> */}
<li className="detailsModal-item">
  <strong>
    {isDoor
      ? t("details.decorativeGlass") // Use "Decorative Glass" for doors
      : t("details.windowGridType") // Use "Grid Type" for windows
    }:
  </strong>{" "}
  {details.windowGridType && isDoor
    ? details.windowGridType // Show the decorative glass value
    : details.windowGridType && !isDoor
    ? details.windowGridType // Show the grid type value
    : t("details.none")} {/* Fallback to "None" */}
</li>



              <li className="detailsModal-item">
                <strong>{t("details.realWidth")}:</strong>
                {details.realWidth !== "N/A" ? details.realWidth : t("details.notAvailable")}
              </li>
              <li className="detailsModal-item">
                <strong>{t("details.realHeight")}:</strong>
                {details.realHeight !== "N/A" ? details.realHeight : t("details.notAvailable")}
              </li>
              <li className="detailsModal-item">
                <strong>{t("details.itemColorName")}:</strong> {t(`itemColor-${details.itemColor}`)}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function generateImageFromCanvasObject(selectedObject, overlayColor, callback) {

  console.log("selected object:", selectedObject)
  if (!selectedObject) {
    console.error("No object selected on the canvas.");
    callback(null);
    return;
  }

  // Create a temporary fabric canvas
  const tempCanvas = new fabric.StaticCanvas(null);

  // Clone the selected object
  selectedObject.clone((clonedObject) => {
    // Apply scaling, position, and rotation
    clonedObject.set({
      left: 0, // Align to the top-left of the temp canvas
      top: 0,
      originX: "left",
      originY: "top",
    });

    // Add the cloned object to the temporary canvas
    tempCanvas.add(clonedObject);

    // Resize the temp canvas to fit the object dimensions
    tempCanvas.setDimensions({
      width: clonedObject.width * clonedObject.scaleX,
      height: clonedObject.height * clonedObject.scaleY,
    });

    // If an overlay color is provided, apply it to the object
    if (overlayColor) {
      const overlayRGBA = parseColor(overlayColor); // Use your parseColor function
      const colorFilter = new fabric.Image.filters.BlendColor({
        color: `rgba(${overlayRGBA.r},${overlayRGBA.g},${overlayRGBA.b},${overlayRGBA.a})`,
        mode: "multiply",
      });

      if (clonedObject.filters) {
        clonedObject.filters.push(colorFilter);
        clonedObject.applyFilters();
      }
    }

    // Render the canvas and export it as a Data URL
    tempCanvas.renderAll();
    const dataURL = tempCanvas.toDataURL(); // Generates the image
    callback(dataURL);
  });
}


function preprocessWindowType(windowType, imageUrl) {
  if (imageUrl?.includes("doors")) {
    // Decode the URL to handle encoded characters
    const decodedUrl = decodeURIComponent(imageUrl);

    // Extract the part after "doors/" and clean up
    const match = decodedUrl.match(/doors\/.+\/(.+?)(NoSidelight|\.png)?$/); // Match the filename and exclude "NoSidelight" or ".png"
    if (match && match[1]) {
      // Remove underscores and clean up the name
      return match[1].replace(/_/g, "").replace("NoSidelight", "");
    }

    return "Unknown Door Type"; // Fallback for doors
  }

  // Process windowType for non-door items
  return windowType.replace(/_/g, ""); // Remove underscores
}





// Function to apply the color overlay only to non-transparent areas
// function applyColorOverlayToNonTransparentAreas(imageUrl, overlayColor, callback) {
//   console.log("applyColorOverlayToNonTransparentAreas called with:");
//   console.log("Image URL:", imageUrl);
//   console.log("Overlay Color:", overlayColor);

//   const canvas = document.createElement("canvas");
//   const ctx = canvas.getContext("2d");

//   const img = new Image();
//   img.crossOrigin = "anonymous"; // Enable cross-origin support if necessary
//   img.src = imageUrl;

//   img.onload = () => {
//     console.log("Image loaded successfully. Dimensions:", img.width, "x", img.height);

//     // Set canvas dimensions to match the image
//     canvas.width = img.width;
//     canvas.height = img.height;

//     // Draw the image onto the canvas
//     ctx.drawImage(img, 0, 0);

//     // Get the image's pixel data
//     const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
//     const data = imageData.data;

//     // Parse the overlay color into RGBA
//     const overlayRGBA = parseColor(overlayColor);
//     console.log("Parsed overlay color:", overlayRGBA);

//     // Loop through every pixel
//     for (let i = 0; i < data.length; i += 4) {
//       const alpha = data[i + 3]; // Alpha channel (transparency)

//       if (alpha > 0) {
//         // Only apply the color if the pixel is not fully transparent
//         data[i] = (data[i] * (1 - overlayRGBA.a)) + (overlayRGBA.r * overlayRGBA.a); // Red
//         data[i + 1] = (data[i + 1] * (1 - overlayRGBA.a)) + (overlayRGBA.g * overlayRGBA.a); // Green
//         data[i + 2] = (data[i + 2] * (1 - overlayRGBA.a)) + (overlayRGBA.b * overlayRGBA.a); // Blue
//       }
//     }

//     console.log("Finished applying color overlay.");

//     // Put the modified image data back onto the canvas
//     ctx.putImageData(imageData, 0, 0);

//     // Return the processed image as a data URL
//     const processedImageUrl = canvas.toDataURL();
//     console.log("Processed image URL generated:", processedImageUrl);
//     callback(processedImageUrl);
//   };

//   img.onerror = (err) => {
//     console.error("Error loading image:", err);
//     callback(null); // Return null in case of an error
//   };
// }


// Helper function to parse a color string into RGBA
function parseColor(color) {
  console.log("Parsing color:", color);

  const ctx = document.createElement("canvas").getContext("2d");
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, 1, 1);
  const rgba = ctx.getImageData(0, 0, 1, 1).data;

  console.log("Parsed RGBA values:", {
    r: rgba[0],
    g: rgba[1],
    b: rgba[2],
    a: rgba[3] / 255,
  });

  return {
    r: rgba[0],
    g: rgba[1],
    b: rgba[2],
    a: rgba[3] / 255, // Normalize alpha to [0, 1]
  };


}

